import { mapActions, mapGetters } from "vuex";

export default {
    name: "contact-page",
    data() {
        return {};
    },
    watch: {
        $route() {
            this.fetchPage("contact-page");
        },
    },
    created() {
        this.fetchPage("contact-page");
    },
    computed: {
        ...mapGetters({
            contacts: "setting/variables",
            page: "pages/page",
        }),

        getContactsWithMap() {
            return this.contacts?.contactInfo.filter(info => !!info.map);
        },

        getContactsWithoutMap() {
            return this.contacts?.contactInfo.filter(info => !info.map);
        },
    },
    methods: {
        ...mapActions({
            fetchPage: "pages/GET_PAGE",
        }),
        /**
         * @param {keyof ContactPage|string} field
         * @return {string}
         */
        getContact(field) {
            return this.contacts?.contactPage[field] || "";
        },
    },
};
